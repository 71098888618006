/*Teaser*/
.q-teaser
  &:hover
    color: $black
    text-decoration: none
  p
    margin-bottom: 0
  .q-teaser-image
    position: relative
    width: 100%
    height: 244px
    overflow: hidden
    @media screen and (max-width: $break-mobile-large)
      height: 189px
    @media screen and (max-width: $break-mobile)
      height: auto
    img
      width: 100%
    &.q-teaser-image-slider
      height: auto

  /*Teaser – GREY BACKGROUND*/
  &.q-greyback
    display: flex
    flex-direction: column
    align-items: center
    border: none
    @media screen and (max-width: $break-mobile-small)
      margin-bottom: 30px
    .q-teaser-small-img-top
      width: 80%
      position: relative
      max-height: 244px
      @media screen and (max-width: $break-mobile-small)
        width: 100%
    .q-greyback-body
      background-color: $lightgrey
      margin-top: -20px
      padding: 50px 1.25rem 50px 1.25rem
      min-height: 240px
      @media screen and (max-width: $break-mobile-small)
        width: 100%
        padding-left: 10px
        padding-right: 10px
        min-height: auto
      h3
        @media screen and (max-width: $break-mobile-small)
          font-size: 1.0125rem
    .q-greyback-footer-wrap
      margin-top: -20px


/*ADD Teaser Fläche*/
.q-add
  background-color: $lightgrey
  padding: 20px
  width: 100%
  height: 100%
  text-decoration: none
  color: $black
  transition: background-color .2s ease-out
  &:hover
    text-decoration: none
    background-color: darken($lightgrey, 3%)
  .q-add-icon
    height: 45px
    width: 45px
    margin-bottom: 10px



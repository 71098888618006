///
/// Paradigm Shift by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Typography */

	html {
		font-size: 18pt;

		@include breakpoint('<=xlarge') {
			font-size: 13pt;
		}

		@include breakpoint('<=medium') {
			font-size: 14pt;
		}

		@include breakpoint('<=small') {
			font-size: 12pt;
		}

		@include breakpoint('<=xsmall') {
			font-size: 11pt;
		}
	}

	body {
		background-color: _palette(bg);
		color: _palette(fg);
		font-family: Averta-Regular,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	}

	footer p,
	header p{
		color: _palette(bg);
		margin-bottom: 0;
		line-height: 1.4;
	}
	footer a{
		color: _palette(bg);
	}
	footer a:hover{
		color: _palette(bg);
	}

	body, input, select, textarea {
		color: _palette(fg-bold);
		font-size: 1rem;
		line-height: 2;
	}

	header nav a{
		border-bottom: none;
	}

	a {
		color: _palette(accent) !important;
		font-size: 18px;
		line-height: 1.4;
		font-weight: 400;
		font-family: Averta-Regular,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

		&:hover {
			text-decoration: none;
			border-bottom-color: transparent;
		}
	}

	em, i {
		font-style: italic;
	}

	p{
		margin: 0 0 _size(element-margin) 0;
		font-weight: 300;
		font-size: 18px;
		line-height: 1.67;
		font-family: Averta-Regular,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	}

	li {
		font-size: 18px;
		line-height: 1.4;
	}

h1, h2, h3, h4, h5, h6 {
		color: _palette(accent);
		line-height: 1.75;
		font-family: Averta-Light,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";


	a {
			color: inherit;
			text-decoration: none;
			font-family: Averta-Regular,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
		}
	}

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.25rem;
	}

	h3 {
		font-size: 0.875rem;
	}

	h4 {
		font-size: 0.875rem;
	}

	h5 {
		font-size: 0.75rem;
	}

	h6 {
		font-size: 0.625rem;
	}
	@include breakpoint('<=large') {
		header h2, h3{
			font-size: 24px !important;
			font-family: Averta-Light,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
		}
		#wrapper{
			padding-bottom: 0 !important;
		}
		#wrapper section > header{
			width: 29.2rem !important;
		}
		.teaser-link-row{
			padding-right: 0 !important;
		}
		.q-close-button-wrapper.q-close-small .q-close-button{
			top: 3px;
		}
		.q-hero-section{
			margin-bottom: 50px;
		}
	}


header h2, h3{
		font-size: 33px !important;
		line-height: 1.24;
	}

	@include breakpoint('<large') {
		#wrapper #first{
			padding-top: 0 !important;
		}
		#wrapper > section > header {
			padding: 1rem 4rem !important;
		}
		#wrapper > section > .content {
			padding: 0 4rem 5rem 4rem !important;
		}
		header h2, h3 {
			font-size: 21px !important;
		}
		a, p, li{
			font-size: 14px;
			line-height: 1.43;
		}
	}
	header h1, header h2, header h3, header h4{
		font-weight: 300 !important;
	}

	@include breakpoint('<=small') {
		h1 {
			font-size: 4.5rem;
			line-height: 1.1;
		}

		h2 {
			font-size: 1.25rem;
			line-height: 1.7;
		}

		h3 {
			font-size: 0.9rem;
		}

		h4 {
			font-size: 0.75rem;
		}

		h5 {
			font-size: 0.675rem;
		}
	}

	@include breakpoint('<=xxsmall') {
		h1 {
			font-size: 3.75rem;
		}

		h2 {
			font-size: 1.125rem;
		}

		h3 {
			font-size: 0.8rem;
		}

		h4 {
			font-size: 0.675rem;
		}

		h5 {
			font-size: 0.675rem;
		}
	}

	sub {
		font-size: 0.8rem;
		position: relative;
		top: 0.5rem;
	}

	sup {
		font-size: 0.8rem;
		position: relative;
		top: -0.5rem;
	}

	blockquote {
		border-left: solid 0.5rem _palette(border);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) * 0.5) 0 (_size(element-margin) * 0.5) _size(element-margin);
	}

	code {
		background: _palette(border-bg);
		border-radius: _size(border-radius);
		font-family: 'Averta';
		font-size: 0.9rem;
		margin: 0 0.25rem;
		padding: 0.25rem 0.65rem;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: 'Averta';
		font-size: 0.9rem;
		margin: 0 0 _size(element-margin) 0;
		width: 100%;

		code {
			display: block;
			line-height: 1.75;
			padding: 1rem 1.5rem;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid 2px _palette(border);
		margin: (_size(element-margin) * 1.5) 0;

		&.major {
			margin: (_size(element-margin) * 2.5) 0;
		}

		@include breakpoint('<=small') {
			&.major {
				margin: (_size(element-margin) * 1.5) 0;
			}
		}
	}


	.q-blue-box h3.white{
		font-size: 14px !important;
		font-family: Averta-Bold,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
		color: #fff;
	}



@media only screen and (min-width: 992px) and (max-width: 1199px) {
	header h2, h3,
	.q-collapse-wrapper .q-collapse .q-collapse-link{
		font-size: 26px !important;
		line-height: 1.24;
	}

	.q-hero-section .q-blue-box .q-blue-box-header {
		font-size: 33px !important;
		line-height: 1.24;
	}
}
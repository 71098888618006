form
  .form-input
    border: 0
    outline: 0
    height: 44px
    margin-bottom: 17px
    padding: 10px 12px
    position: relative

.form
  margin: 0
  .form-group
    margin-bottom: 15px
    min-width: 20%
    .form-input
      background-color: $lightgrey
      margin: 0
      width: 100%
    .form-control
      background-color: $lightgrey
      border: none
      -webkit-border-radius: 0
      -moz-border-radius: 0
      border-radius: 0
    label
      display: block
      font-size: 14px
      margin-bottom: 0

.custom-check-wrapper
  display: block
  position: relative
  padding-left: 35px
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  a
    font-size: inherit
    &:hover
      color: inherit
  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0
    &:checked ~ .checkmark
      &::after
        display: block
  .checkmark
    position: absolute
    top: 0
    left: 0
    height: 20px
    width: 20px
    border: 2px solid $darkgrey
    &.light
      border-color: $white
      &:after
        border-color: $white
    &::after
      content: ""
      position: absolute
      display: none
      left: 6px
      top: 2px
      width: 5px
      height: 10px
      border: solid $darkgrey
      border-width: 0 2px 2px 0
      -webkit-transform: rotate(45deg)
      -ms-transform: rotate(45deg)
      transform: rotate(45deg)

.login-form
  @media screen and (min-width: $break-mobile-large)
    width: 85%
  .form-input
    width: 100%

.MuiFormControl-root.MuiTextField-root.basicTextfield
  width: 100%
.MuiTextField-root.textarea
  width: 100%
  .MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline
    textarea
      height: 120px !important

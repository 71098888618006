/*BJE*/
.q-image-text
  .q-image-col
    .q-image
      &::after
        content: ''
        position: absolute
        width: 50px
        top: -50px
        bottom: 90px
        background-color: rgba(0,159,227,.9)
        @media screen and (max-width: $break-mobile-small)
          display: none
  &.q-has-bg
    .q-image-text-col
      &:nth-child(2)
        &::before
          content: ''
          position: absolute
          width: 1000000px
          margin-left: 0
          right: 0
          background-color: $lightgrey
          z-index: -1
          top: 20%
          bottom: -65px
          @media screen and (max-width: $break-mobile-small)
            top: -25px
            bottom: -25px
      .q-map-row
        bottom: -95px
        width: 100%
        @media screen and (max-width: $break-mobile-small)
          bottom: 0
          margin: 0
          margin-top: 30px
          text-align: center
        .q-map
          width: 100%
  .q-text-right
    .q-image-col
      .q-image
        &::after
          left: 0
/*IMAGE GRID bsp. /620-detailServiceTools.html */
.q-image-grid
  &.q-has-bg
    .col-12:nth-child(2)
      &::before
        content: ''
        position: absolute
        width: 100000000px
        margin-right: 0
        right: 0
        left: -60%
        background-color: $lightgrey
        z-index: -1
        top: 25%
        bottom: -100px
        @media screen and (max-width: $break-mobile)
          top: -40px
          bottom: -35px
        @media screen and (max-width: $break-mobile-small)
          top: -15px

.modal
  .modal-content
    background-color: $main
    color: $white
    border: none
    border-radius: 0
    box-shadow: none
    h1, h2, h3
      color: inherit
    .modal-header
      border-bottom: 0
      .close
        font-size: 2.5rem
        font-weight: 100
        line-height: 1
        color: #fff
        text-shadow: none
        opacity: 1
    .modal-body
      padding: 0 50px 50px 50px
  &.q-fullscreen-modal
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    .modal-dialog
      max-width: 100%
      position: fixed
      margin: 0
      width: 100%
      height: 100%
      padding: 0
      .modal-content
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        overflow-y: auto
        /*SYSTEM UND APP MANUAL MODALS*/
        .q-manual-modal
          color: $black
          h2, h3
            font-weight: 400
            font-size: 2.0625rem
          .q-modal-anchorlinks
            .nav-item
              .nav-link
                background-color: transparent
                color: $black
                font-size: 1.55rem
                font-weight: 100
                &.active
                  color: $main
            @media screen and (max-width: $break-mobile-large)
              .nav
                width: 100%
                flex-direction: row !important
                .nav-item
                  .nav-link
                    font-size: 1.0rem
        .modal-header
          width: 100%
          top: 0
          background-color: $main
          z-index: 9
          @media screen and (max-width: $break-mobile-large)
            margin-bottom: 25px
            img
              max-height: 50px
          .close
            position: fixed
            top: 20px
            right: 50px
            @media screen and (max-width: $break-mobile)
              right: 30px
  &.q-video-modal
    max-width: 100%
    .modal-dialog
      max-width: 900px !important
      .modal-content
        background-color: transparent
        .q-responsive-embed-youtube
          position: relative
          padding-bottom: 56.25% /* 16:9 Aspect Ratio */
          padding-top: 25px
          height: 20vw
          width: 50vw
          @media screen and (max-width: $break-mobile)
            height: 20vw
            width: 80vw
          @media screen and (min-width: $break-mobile-hd)
            width: 800px
          iframe
            position: absolute
            width: 100%!important
            height: 100%!important
    .modal-header
      position: fixed
      width: 100%
      top: 0
      right: 20px
      .close
        color: $main
        font-size: 4.5rem

  &.show
    display: flex !important
    justify-content: center
    align-items: center


.modal-backdrop.show
  background-color: $white
  opacity: .9
  height: 100%
.q-blue-modal-backdrop
  .modal-backdrop.show
    background-color: $main
    opacity: 1
    height: 100%



/*FILTER ERWEITERUNGEN DROPDOWN*/
.nav-mega
  .dropdown
    position: static
    .dropdown-menu
      &.megamenu
        transform: translate3d(0px, 70px, 0px) !important
        top: 50px !important
        padding: 50px
        -moz-box-sizing: border-box
        -webkit-box-sizing: border-box
        box-sizing: border-box
        min-width: auto
        width: 100%
        margin-top: 0
        border: none
        border-radius: 0
        background-color: $main
        color: $white
        h1, h2, h3
          color: $white
      .close
        position: absolute
        top: -30px
        right: -20px
        font-size: 2.5rem
        font-weight: 100
        line-height: 1
        color: #fff
        text-shadow: none
        opacity: 1

@keyframes slideIn
  0%
    transform: translateY(-1rem)
    opacity: 0

  100%
    transform: translateY(0rem)
    opacity: 1

  0%
    transform: translateY(-1rem)
    opacity: 0

.slideIn
  animation-duration: 0.3s
  -webkit-animation-duration: 0.3s
  animation-fill-mode: both
  -webkit-animation-fill-mode: both
  -webkit-animation-name: slideIn
  animation-name: slideIn

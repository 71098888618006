.q-list-item-link
  color: inherit
  &:hover
    color: inherit
    text-decoration: none
  .q-list-item
    margin-top: 30px
    .q-list-img
      img
        width: 100%
    .q-list-headline
      font-size: 14px
      text-transform: uppercase
      font-weight: lighter
      margin-bottom: 0
    p
      margin-bottom: 0


.q-list-item-link
  &:hover
    .q-add-item
      .q-add
        &.item
          background-color: darken($lightgrey, 3%)

.q-add-item
  margin-top: 30px
  padding-top: 30px
  .q-add
    &.item
      padding: 40px 0
      .q-add-icon
        height: 25px
        width: 25px
        margin-bottom: 0


.q-accordion
  .q-accordion-card
    .q-accordion-card-header
      border-bottom: $dots
      &.q-big-header
        .accordion-toggle
          p
            font-size: 2.0625rem
            font-weight: 300
            margin-bottom: 0
            padding: 15px 0
            @media screen and (max-width: $break-mobile-large)
              font-size: 1.6rem
            @media screen and (max-width: $break-mobile)
              font-size: 1.2rem
            @media screen and (max-width: $break-mobile-small)
              font-size: 1rem
      .accordion-toggle
        padding: 10px 0
        color: $black
        .date
          color: $main
          margin-right: 15px
        .q-close-button-wrapper
          display: flex
          align-items: center
          .q-close-button
            line-height: .7
            right: 0
            &::after
              font-size: 58px
              color: $main
              @media screen and (max-width: $break-mobile)
                font-size: 38px
        &:hover
          text-decoration: none
    .q-accordion-card-body
      padding-top: 20px
  &.dark
    .q-accordion-card
      .q-accordion-card-header
        &.q-big-header
          p
            font-weight: 600
          .date
            font-weight: 300
            font-size: 1.475rem
            color: $black
            margin-right: 5px
            @media screen and (max-width: $break-mobile-large)
              font-size: 1.0rem
            @media screen and (max-width: $break-mobile)
              font-size: 0.9rem

  &.q-light
    color: $white
    .q-accordion-card
      border-bottom: $dots-light
      .q-accordion-card-header
        border-bottom: none
        p
          color: $white
        .q-close-button-wrapper
          .q-close-button
            line-height: .7
            right: 0
            &::after
              font-size: 58px
              color: $white



html
  scroll-behavior: smooth
body
  margin: 0
  font-family: Averta-Regular,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
  font-size: 1rem
  font-weight: 400
  line-height: 1.5
  color: $black
  text-align: left
  background-color: $white
  -webkit-font-smoothing: antialiased
  -webkit-hyphens: auto
  -ms-hyphens: auto
  hyphens: auto

h1, h2
  font-size: 1.875rem
  line-height: 1.24
  font-weight: 300 !important
  letter-spacing: 1px !important
  color: $main
  margin-top: 0
  margin-bottom: .5rem
  font-family: Averta-Light,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
  @media screen and (min-width: $break-mobile-large)
    font-size: 2.825rem

h3, h4, h3 a
  font-size: 1.3125rem
  line-height: 1.24
  font-weight: 300
  color: $main
  @media screen and (min-width: $break-mobile-large)
    font-size: 2.0625rem

h4
  font-size: 1.1125rem
  @media screen and (min-width: $break-mobile-large)
    font-size: 1.4rem


.stroke-short-solid
  position: relative
  padding-bottom: 20px
  margin-bottom: 20px
  @media screen and (min-width: $break-mobile-small)
    padding-bottom: 35px
    margin-bottom: 35px
  &::after
    width: 24px
    height: 2px
    content: ''
    position: absolute
    bottom: 0
    left: 0
    background-color: currentColor

.stroke-short-solid.q-stroke-small
  position: relative
  padding-bottom: 20px
  margin-bottom: 20px
  @media screen and (min-width: $break-mobile-small)
    padding-bottom: 15px
    margin-bottom: 35px

a
  color: $main
  text-decoration: none
  background-color: transparent
  &:hover
    color: #006997
    text-decoration: underline
    text-decoration-thickness: 0.1em
  &.light
    color: $white
    &:hover
      color: $white
  &.dark
    color: $black
    &:hover
      color: $darkgrey
.q-underline
  text-decoration: underline
.q-no-underline
  text-decoration: none !important
#q-page-content
  padding-top: 55px
  margin-bottom: 10px
  @media screen and (max-width: $break-mobile-small)
    margin-bottom: 50px


.container
  max-width: 1200px
  @media screen and (max-width: $break-mobile-small)
    padding-right: 10px
    padding-left: 10px
  @media screen and (min-width: $break-mobile-small)
    max-width: 950px
  @media screen and (min-width: $break-mobile-large)
    max-width: 1200px

/*HELPER*/
*:focus
  outline-color: transparent !important
  outline: none !important
  box-shadow: none !important

.text-transform-none
  text-transform: none !important

.q-mt-large
  margin-top: 150px
  @media screen and (max-width: $break-mobile)
    margin-top: 50px

.q-mb-large
  margin-bottom: 150px
  @media screen and (max-width: $break-mobile)
    margin-bottom: 50px

.q-pt-mobile
  @media screen and (max-width: $break-mobile-small)
    padding-top: 50px

.q-text-small
  font-size: 14px

.q-text-big
  font-size: 33px
  line-height: 1.24
  font-family: Averta-Light,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
  @media screen and (max-width: $break-mobile-bje)
    font-size: 21px
.q-link
  &:hover
    text-decoration: none

.q-p-mb-0 /*Alle <p></p> ohne margin bottom*/
  p
    margin-bottom: 0

.q-bg-grey
  background-color: $lightgrey

.blue
  color: $main
.light
  color: $white
.black
  color: $black
.q-grey
  color: $darkgrey

.bottom-dots
  padding-bottom: 0.2em
  border-bottom: $dots

.bottom-dots-light
  padding-bottom: 0.2em
  border-bottom: $dots-light

.q-bg-transparent
  background-color: transparent !important

.q-small-font
  font-size: 16px
  p, a
    font-size: 16px

.q-small-icon
  width: 20px

.q-medium-icon
  $size: 60px
  max-width: $size
  max-height: $size

.q-info-text
  font-style: italic
  font-size: 14px

.bottom-dots-grey
  padding-bottom: 0.2em
  border-bottom: $dots-grey

.top-dots
  padding-top: 0.2em
  border-top: $dots

.q-dropdown-icon
  display: flex
  &::before
    display: none
  &::after
    content: ""
    font-family: icon-font
    font-weight: 400
    font-size: 20px
    line-height: 1.5
    color: inherit
    margin-left: 10px
    @media screen and (max-width: $break-mobile-large)
      line-height: 1
  &:hover
    text-decoration: none
.q-truncate
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
.q-mobile-relative
  @media screen and (max-width: $break-mobile-small)
    position: relative !important

.q-col-hidden
  @media screen and (max-width: $break-mobile)
    display: none

.q-img-shadow
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.30)

.q-img-hover-effect
  width: 100%
  height: auto
  overflow: hidden
  img
    -webkit-transform: scale(1)
    transform: scale(1)
    -webkit-transition: .3s ease-in-out
    transition: .3s ease-in-out
  &:hover
    img
      -webkit-transform: scale(1.1)
      transform: scale(1.1)
      
.q-cursor-pointer
  cursor: pointer

.q-smooth-scroll
  scroll-behavior: smooth


.chat
  margin-bottom: 10px
  display: flex
  flex-direction: column
  .message
    width: 85%
    border: 1px solid $lightgrey
    padding: 10px 15px
  &.chat-left
    .message
        background-color: $main
        color: $white
  &.chat-right
    display: flex
    align-items: flex-end
    .message
      background-color: $lightgrey
/*BJE*/
.q-blue-border-left
  &::after
    $width: 20px
    content: ''
    position: absolute
    width: $width
    top: -50px
    height: 65%
    left: -$width
    background-color: rgba(0,159,227,.9)
    @media screen and (max-width: $break-mobile-large)
      $width: 15px
      width: $width
      left: -$width
    @media screen and (max-width: $break-mobile-small)
      display: none

a.teaser-link
  border-bottom: none
  text-decoration: none !important
  p
    font-size: 14px !important

.image.small img
  max-height: 130px
  width: auto
  max-width: 100%

/**/
.loadingScreen
  display: flex !important
  flex-direction: column
  height: 100vh
  width: 100%
  justify-content: center
  align-items: center
  .loader-text
    margin-left: -50px

/*Z-INDEXES*/
header
  z-index: 11
.q-hero-content, .q-hero-image-small
  z-index: 10
.q-anchorlinks
  z-index: 9

#wrapper
  padding: 1rem 0 10rem 0 !important
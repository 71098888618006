.page-footer
  color: $white
  h5
    font-weight: 300
    font-size: 1.875rem
  p
    margin-bottom: 5px
    a
      font-size: 16px
      color: $white
      &:hover
        color: inherit

  .footer-meta
    background-color: $white
    color: $black
    .links
      a
        color: $black
        &:hover
          color: inherit
      span, a
        font-size: 14px
        margin-right: 20px
        @media screen and (max-width: $break-mobile-small)
          width: 100%
          margin-bottom: 10px
          text-align: center
    .social
      a
        margin-left: 20px
        margin-right: 0
        color: $black
        i
          font-size: 24px
          &:hover
            color: $darkgrey
///
/// Paradigm Shift by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;

			&:last-child {
				padding-right: 0;
			}

			.icon {
				@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out');
				width: 2.25rem;
				height: 2.25rem;
				text-align: center;
				line-height: 2.25rem;
				border-radius: 2.25rem;
				display: inline-block;

				&:before {
					font-size: 1.25rem;
				}

				&:hover {
					background-color: _palette(border-bg);
				}
			}
		}
	}
.q-collapse-wrapper
  .q-collapse
    margin-top: 30px
    margin-bottom: 10px
    .q-collapse-link
      font-size: 33px
      line-height: 1.24
      font-weight: 300
      border-bottom: none !important
      color: $black !important
      font-family: Averta-Light
      @media screen and (max-width: $break-mobile-bje)
        font-size: 21px
      &.q-btn-primary
        color: $white !important
        background-color: $main
        border-color: $main
        font-size: .75rem
        width: max-content
        border-bottom: 1px solid $main !important
        font-family: Averta-Semibold
        &:hover
          color: $main !important
          background-color: $white
          border-color: $main
          text-decoration: none
        .q-close-button-wrapper
          display: none
      &.q-btn-secondary
        color: $main !important
        background-color: $white
        border-color: $main
        font-size: .75rem
        width: max-content
        border-bottom: 1px solid $main !important
        &:hover
          color: $white !important
          background-color: $main
          border-color: $main
          text-decoration: none
        .q-close-button-wrapper
          display: none


      &:hover
        text-decoration: none
.q-close-button-wrapper
  margin-right: 10px
  .q-close-button
    line-height: .7
    top: 0
    right: 0px
    &::after
      content: '✕'
      font-weight: 300
      font-size: 48px
      right: 0
      display: block
      transition: all 0.15s ease-out
        line-height: 1
      color: $main !important
      @media screen and (max-width: $break-mobile-bje)
        font-size: 30px
        line-height: 0.8
  &.q-close-small
    .q-close-button
      line-height: 1
      margin-left: -20px
      &::after
        font-size: 24px

.collapsed
  text-decoration: none
  .q-close-button
    &::after
      transform: rotate(45deg) !important



/* Toggle Link Text */
.q-toggle-text
  &.true
    display: none
  &.false
    display: inline-block

a[aria-expanded="true"]
  .q-toggle-text
    &.true
      display: inline-block
    &.false
      display: none
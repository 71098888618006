.q-table
  th, td
    border-top: none
    border-bottom: none !important
  tbody
    tr
      border-bottom: $dots-grey

/*MODAL MATRIX TABLE*/
.q-table-matrix
  color: $white
  thead
    tr
      border-bottom: $dots-light !important
      th
        text-align: center
        font-weight: 400
        font-size: 14px
        border-left: $dots-light
        &:first-child
          border-left: none
        .q-th-img
          max-width: 100px
          margin-left: auto
          margin-right: auto
          margin-bottom: 10px
        &.q-hover
          border-bottom: 2px solid $white !important
  tbody
    tr
      border-bottom: $dots-light !important
      th
        vertical-align: middle
        font-weight: 400
        font-size: 14px
        text-align: right
        text-transform: uppercase
      td
        border-left: $dots-light
        vertical-align: middle
        text-align: center
        overflow: hidden
        border-bottom: none !important
        padding: 0
        .round
          &:hover
            &:after, &:before
              display: inline-block
          label
            width: 100%
            height: 100%
            padding: 30px 0
            margin: 0
            display: flex
            justify-content: center
            align-items: center
            position: relative
            opacity: 0
            &:before
              content: " "
              display: inline-block
              background-color: transparent
              border-radius: 50%
              cursor: pointer
              border: 1px solid $white
              height: 20px
              width: 20px
              padding: 0
              position: relative
            &:after
              content: "✕"
              font-size: 28px
              position: absolute
              color: $main
              display: none
              pointer-events: none
              margin-left: -7px
            &:hover
              opacity: 1
            &.checked
              opacity: 1
              &:before
                background-color: $white
              &:hover
                &:after
                    display: inline-block
          input
            visibility: hidden



.swiper-container
  width: 100%
  height: 100%
.slider
  position: relative
  .swiper-button-next
    right: -30px
    @media screen and (max-width: $break-mobile-small)
      right: 30px
  .swiper-button-prev
    left: -30px
    @media screen and (max-width: $break-mobile-small)
      left: 30px
    &::after
      -webkit-transform: scaleX(-1)
      transform: scaleX(-1)
  .swiper-button-next, .swiper-button-prev
    outline-color: transparent
    outline: none
    top: 30%
    &::after
      content: ""
      color: $black
      font-family: icon-font
      line-height: 0.3
      position: absolute
      font-weight: 400
      font-size: 210%
.swiper-pagination
  width: 100%
  @media screen and (max-width: $break-mobile-small)
    width: auto
    position: relative !important
    padding-top: 0 !important
    display: none
  .swiper-pagination-bullet
    margin: 0 5px
  .swiper-pagination-bullet-active
    background-color: $main

.q-teaser-image-slider
  .q-teaser-image
    height: auto
    .swiper-buttons
      position: absolute
      bottom: 0
      left: 0
      display: flex
      justify-content: space-between
      width: 100%
      .swiper-button-next, .swiper-button-prev
        position: relative
        transition: all 0.15s ease-out
    @media screen and (max-width: $break-mobile)
      .swiper-button-next, .swiper-button-prev
        background-color: $lightgrey
        padding: 5px 10px
        width: auto
        transition: all 0.15s ease-out
        &:after
          position: relative
          font-size: 160%
      .swiper-button-next
        right: 0
      .swiper-button-prev
        left: 0
    .q-teaser-images-count
      position: absolute
      bottom: 0
      right: 0
      z-index: 1
      background-color: $white
      padding: 5px 10px
      display: block
      transition: all 0.15s ease-out
      @media screen and (max-width: $break-mobile)
        bottom: auto
        top: 0
        display: block !important

  &:hover
    .q-teaser-images-count
      display: none
    .swiper-buttons
      .swiper-button-next, .swiper-button-prev
        position: relative
        background-color: $lightgrey
        padding: 5px 10px
        width: auto
        transition: all 0.15s ease-out
        &::after
          position: relative
          font-size: 160%
          transition: all 0.15s ease-out
        &.swiper-button-disabled
          opacity: 0.5
        &:hover
          background-color: $main
          &::after
            color: $white
      .swiper-button-next
        right: 0
      .swiper-button-prev
        left: 0

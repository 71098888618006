/*variables*/
$main: #009fe3
$lightgrey: #F2F2F2
$grey: #D8D8D8
$darkgrey: #9B9B9B
$white: #fff
$black: #000
$active: #71D477
$problem: #FFAA28
$disabled: #DEDEDE
$hover-link: #00699B

$dots: 1px dotted $main
$dots-grey: 1px dotted $darkgrey
$dots-light: 1px dotted $white

$break-mobile-hd: 1990px
$break-mobile-large: 1400px
$break-mobile-bje: 1023px
$break-mobile: 980px
$break-mobile-small: 768px
$break-mobile-quer-min: 992px
$break-mobile-quer-max: 1199px

.q-icon-headline-wrapper
  display: flex
  align-items: flex-end
  padding-bottom: 10px
  border-bottom: $dots
  margin-bottom: 25px
  @media screen and (max-width: $break-mobile-small)
    display: block
    .q-headline-main
      margin-bottom: 10px
      .q-icon-headline
        margin: 0 0 0 5px
  .q-headline-main
    display: flex
    align-items: flex-end
    .q-icon-headline
      margin: 0 20px
  span
    font-weight: 400
  .q-headline-icon
    width: 35px
    height: 35px
